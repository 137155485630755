import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import toast from "react-hot-toast";
import { HOST_API_KEY } from '../../config-global';

export const blogSlice = createSlice({
    name: "blogs",
    initialState: {
        blogs: [],
        loading: false,
        message: "",
        categories: []
    },
    reducers: {
        setBlogs(state, action) {
            state.blogs = action.payload;
        },
        setMessage(state, action) {
            state.message = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setCategories(state, action) {
            state.categories = action.payload;
        }
    }
})

export const { setBlogs, setMessage, setLoading, setCategories } = blogSlice.actions;
export default blogSlice.reducer;

export const getBlogs = () => async (dispatch) => {
    try {
        const result = await axios.get("/api/blog");
        dispatch(setBlogs(result.data.data));
    } catch (error) {
        console.log(error);
    }
}

export const insertBlog = (blog) => async (dispatch) => {
    try {
        const requestBody = blog;
        const requestOption = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: blog
        }

        const result = await fetch(`${HOST_API_KEY}/api/blog`, requestOption).then(res => res.json());
        // const result = await axios.post('blog', blog);
        toast.success(result.message)
        if (result.success) {
            dispatch(getBlogs());
          }
          dispatch(setMessage(result.data.message))
          dispatch(setLoading(false));
    } catch (error) {
        console.log(error);
    }
}

export const getCategories = () => async (dispatch) => {
    try {
        const result = await axios.get("/api/category");
        dispatch(setCategories(result.data.data))
    } catch (error) {
        console.log(error)
    }
}

export const insertCategory = (category) => async (dispatch) => {
    try {
        const result = await axios.post("/api/category", { category });

        toast.success(result.data.message)
        if (result.data.success) {
            dispatch(getBlogs());
        }
        dispatch(setMessage(result.data.message))
        dispatch(setLoading(false));
    } catch (error) {
        console.log(error)
    }
}