// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  {
    items: [
      { title: "Dashboard", path: PATH_DASHBOARD.dashboard, icon: ICONS.dashboard}
    ]
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'DOC MANAGEMENT',
    items: [
      { title: "University", path: PATH_DASHBOARD.universitys.root, icon: ICONS.dashboard},
      { title: "Course", path: PATH_DASHBOARD.courses.root, icon: ICONS.dashboard},
      { title: "Task", path: PATH_DASHBOARD.tasks.root, icon: ICONS.dashboard},
    ],
  },
  {
    subheader: 'BLOG MANAGEMENT',
    items: [
      { title: "Blogs", path: PATH_DASHBOARD.blogs.root, icon: ICONS.dashboard },
    ]
  },
  {
    subheader: 'CONTENT MANAGEMENT',
    items: [
      { title: "contents", path: PATH_DASHBOARD.contents.root, icon: ICONS.dashboard },
    ]
  },
  {
    subheader: "Setting",
    items: [
      { title: "Profile", path: PATH_DASHBOARD.profile, icon: ICONS.dashboard },
    ]
  },
  {
    subheader: 'USERS MANAGEMENT',
    role : 'super',
    items: [
      { title: 'users', path: PATH_DASHBOARD.users, icon: ICONS.dashboard }
    ]
  }
];

export default navConfig;
