import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import toast from "react-hot-toast";
import { HOST_API_KEY } from '../../config-global';

export const courseSlice = createSlice({
    name: "courses",
    initialState: {
        courses: [],
        loading: false,
        message: ""
    },
    reducers: {
        setCourses(state, action) {
            state.courses = action.payload;
        },
        setMessage(state, action) {
            state.message = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        }
    }
})

export const { setCourses, setMessage, setLoading } = courseSlice.actions;
export default courseSlice.reducer;

export const getCourses = () => async (dispatch) => {
    try {
        const result = await axios.get("/api/course");
        dispatch(setCourses(result.data.data));
    } catch (error) {
        console.log(error);
    }
}

export const insertCourse = (course) => async (dispatch) => {
    try {
        dispatch(setLoading(true))
        const requestOption = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: course
        }
        // const result = await axios.post("course", course);
        const result = await fetch(`${HOST_API_KEY}/api/course`, requestOption).then(res => res.json());
        const message = result.message;
        toast.success(message)
        if (result.success) {
            dispatch(getCourses());
        }

        dispatch(setMessage(message));
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
    }
}

export const uploadDocuments = (document) => async (dispatch) => {
    try {
        dispatch(setLoading(true))
        const requestOption = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: document
        }
        // const result = await axios.post("course", course);
        const result = await fetch(`${HOST_API_KEY}/api/course/upload`, requestOption).then(res => res.json());
        const message = result.message;
        toast.success(message)
        if (result.success) {
            dispatch(getCourses());
        }

        dispatch(setMessage(message));
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
    }
}

export const deleteCourse = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true))
        const result = await axios.delete(`/api/course/${id}`);
        if (result.data.success) {
            dispatch(getCourses());
        }
        toast.success(result.data.message)
        dispatch(setMessage(result.data.message));
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);

    }
}

export const approveCourse = (id, is_approved) => async (dispatch) => {
    try {
        setLoading(false);
        const response = await axios.post("/api/course/approve", { id, is_approved });
        toast.success(response.data.message);
        if (response.data.success) {
            dispatch(getCourses());
        }
    } catch (error) {

    }
}

export const updateReview = (id, reviews) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const result = await axios.post("/api/course/review", { id, reviews });
        if (result.data.success) {
            dispatch(getCourses());
        }
        toast.success(result.data.message);
        dispatch(setMessage(result.data.message));
        dispatch(setLoading(false));
    } catch (error) {
        console.log(error);
    }
}