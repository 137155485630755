import { configureStore } from "@reduxjs/toolkit";
import universityReducer from "./reducers/university";
import courseReducer from './reducers/course';
import taskReducer from "./reducers/task";
import blogReducer from "./reducers/blog";
import contentReducer from "./reducers/content";
import userReducer from './reducers/user';

export default configureStore({
    reducer: {
        universitys: universityReducer,
        courses: courseReducer,
        tasks: taskReducer,
        blogs: blogReducer,
        contents: contentReducer,
        users: userReducer
    }
})