import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import toast from "react-hot-toast";


export const universitysSlice = createSlice({
  name: "universitys",
  initialState: {
    universitys: [],
    loading: false,
    message: ""
  },
  reducers: {
    setUniversitys (state, action) {
      state.universitys = action.payload;
    },
    setMessage (state, action) {
      state.message = action.payload;
    },
    setLoading (state, action) {
      state.loading = action.payload
    }
  }
})

export const { setUniversitys, setMessage, setLoading } = universitysSlice.actions;
export default universitysSlice.reducer;

export const getUniversitys = () => async (dispatch) => {
  try {
      const result = await axios.get("/api/university");
      dispatch(setUniversitys(result.data.data));
      // dispatch(setMessage("Successfully Loaded"))
  } catch (error) {
      dispatch(setMessage("Error"))
    
  }
}

export const insertUniversitys = ( university ) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const result = await axios.post("/api/university", university);
    toast.success(result.data.message)
    if (result.data.success) {
      dispatch(getUniversitys());
    }
    dispatch(setMessage(result.data.message))
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setMessage("Error"))
  }
}

export const deleteUniversity = (id) => async (dispatch) => {
  try {
      dispatch(setLoading(true));
      const result = await axios.delete(`/api/university/${id}`);
      toast.success(result.data.message)
      if (result.data.success) {
          dispatch(getUniversitys());
      }
      dispatch(setMessage(result.data.message));
      dispatch(setLoading(false));
  } catch (error) {
      dispatch(setMessage("Error"))
  }
}
