import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  HomePage,
  Page404,
  LoginPage,
  RegisterPage,
  UserPage,
  UniversityPage,
  AddCoursePage,
  CoursePage,
  DashBoardPage,
  ProfilePage,
  UniversityEditPage,
  UniversityNewPage,
  CourseEditPage,
  CourseNewPage,
  TaskPage,
  TaskEditPage,
  TaskNewPage,
  BlogPostsPage,
  BlogNewPostPage,
  BlogPostPage,
  ContentPostsPage,
  ContentNewPostPage,
  ContentPostPage,
  CourseReviewPage,
  TaskReviewPage
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage/>
            </GuestGuard>
          )
        }
      ],
    },
    {
      path: '/admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "dashboard", element: <DashBoardPage/>},
        { path: "universitys", element: <UniversityPage/>},
        { path: "universitys/:id", element: <UniversityEditPage/>},
        { path: "universitys/new", element: <UniversityNewPage/>},
        { path: "universitys/addCourse/:id", element: <AddCoursePage/> },
        { path: "courses", element: <CoursePage/>},
        { path: "courses/:id", element: <CourseEditPage/>},
        { path: "courses/new", element: <CourseNewPage/>},
        { path: "courses/review/:id", element: <CourseReviewPage/> },
        { path: 'tasks', element: <TaskPage/> },
        { path: 'tasks/:id', element: <TaskEditPage/>},
        { path: 'tasks/new', element: <TaskNewPage/>},
        { path: 'tasks/review/:id', element: <TaskReviewPage/> },
        { path: "profile", element: <ProfilePage/>},
        { path: "blogs", element: <BlogPostsPage/> },
        { path: "blogs/new", element: <BlogNewPostPage/>},
        { path: "blogs/:title", element: <BlogPostPage/>},
        { path: "contents", element: <ContentPostsPage/> },
        { path: "contents/new", element: <ContentNewPostPage/>},
        { path: "contents/:title", element: <ContentPostPage/>},
        { path: "users", element: <UserPage/> }
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
