import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));


export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/RegisterPage')));

export const UniversityPage = Loadable(lazy(() => import('../pages/university/UniversityPage')));
export const UniversityEditPage = Loadable(lazy(() => import('../pages/university/pages/UniversityEditPage')));
export const UniversityNewPage = Loadable(lazy(() => import('../pages/university/pages/UniversityNewPage')));
export const AddCoursePage = Loadable(lazy(() => import('../pages/university/pages/AddCoursePage')));

export const CoursePage = Loadable(lazy(() => import('../pages/course/CoursePage')));
export const CourseEditPage = Loadable(lazy(() => import('../pages/course/pages/CourseEditPage')));
export const CourseNewPage =  Loadable(lazy(() => import('../pages/course/pages/CourseNewPage')));

export const TaskPage = Loadable(lazy(() => import('../pages/task/TaskPage')));
export const TaskEditPage = Loadable(lazy(() => import('../pages/task/pages/TaskEditPage')));
export const TaskNewPage = Loadable(lazy(() => import('../pages/task/pages/TaskNewPage')));
export const TaskReviewPage = Loadable(lazy(() => import('../pages/task/pages/TaskReviewPage')));

export const BlogNewPostPage = Loadable(lazy(() => import('../pages/blog/BlogNewPostPage')));
export const BlogPostPage = Loadable(lazy(() => import("../pages/blog/BlogPostPage")));
export const BlogPostsPage = Loadable(lazy(() => import("../pages/blog/BlogPostsPage")));

export const ContentPostPage = Loadable(lazy(() => import('../pages/content/ContentPostPage')));
export const ContentNewPostPage = Loadable(lazy(() => import('../pages/content/ContentNewPostPage')));
export const ContentPostsPage = Loadable(lazy(() => import("../pages/content/ContentPostsPage")));

export const CourseReviewPage = Loadable(lazy(() => import('../pages/course/pages/CourseReviewPage')));


export const DashBoardPage = Loadable(lazy(() => import('../pages/Dashboard/Dashboard')));
export const ProfilePage = Loadable(lazy(() => import('../pages/profile/Profile')));

export const UserPage = Loadable(lazy(() => import('../pages/user/UserPage')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
