import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import toast from "react-hot-toast";
import { HOST_API_KEY } from '../../config-global';

export const taskSlice = createSlice({
    name: "tasks",
    initialState: {
        tasks: [],
        loading: false,
        message: ""
    },
    reducers: {
        setTasks(state, action) {
            state.tasks = action.payload;
        },
        setMessage(state, action) {
            state.message = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        }
    }
})

export const { setTasks, setMessage, setLoading } = taskSlice.actions;
export default taskSlice.reducer;

export const getTasks = () => async (dispatch) => {
    try {
        const result = await axios.get("/api/task");
        dispatch(setTasks(result.data.data));
    } catch (error) {
        console.log(error);
    }
}

export const generateTasks = (data) => async (dispatch) => {
    try {
        const result = await axios.post("/api/task/generate", data);
        toast.success(result.data.message)
        if (result.data.success) {
            dispatch(setMessage(result.data.message))
        }
    } catch (error) {
        console.log(error)
    }
}

export const insertTask = (task) => async (dispatch) => {
    try {
        const requestBody = task;
        const requestOption = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: task
        }
        const result = await fetch(`${HOST_API_KEY}/api/task`, requestOption).then(res => res.json());
        // const result = await axios.post('task', task);
        toast.success(result.message)
        if (result.data.success) {
            dispatch(getTasks());
          }
          dispatch(setMessage(result.data.message))
          dispatch(setLoading(false));
    } catch (error) {
        console.log(error);
    }
}

export const deleteTask = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true))
        const result = await axios.delete(`/api/task/${id}`);
        toast.success(result.data.message)
        if (result.data.success) {
            dispatch(getTasks());
        }
        dispatch(setMessage(result.data.message));
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error)
    }
}

export const approveTask = (id, is_approved) => async (dispatch) => {
    try {
      setLoading(false);
      const response = await axios.post("/api/task/approve", { id, is_approved });
      toast.success(response.data.message);
      if (response.data.success) {
        dispatch(getTasks());
      }
    } catch (error) {
      
    }
  }

export const updateReview = (id, reviews) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const result = await axios.post("/api/task/review", { id, reviews });
      if(result.data.success) {
        dispatch(getTasks());
      }
      toast.success(result.data.message);
      dispatch(setMessage(result.data.message));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
    }
  }