import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import toast from "react-hot-toast";
import { HOST_API_KEY } from '../../config-global';

export const userSlice = createSlice({
    name: "users",
    initialState: {
        users: [],
        loading: false,
        message: "",
    },
    reducers: {
        setUsers(state, action) {
            state.users = action.payload;
        },
    }
})

export const { setUsers } = userSlice.actions;
export default userSlice.reducer;


export const getUsers = () => async (dispatch) => {
    try {
        const result = await axios.get("/api/auth/get-users");
        dispatch(setUsers(result.data.data));
    } catch (error) {
        console.log(error);
    }
}

export const allowUser = (userId, is_approved) => async (dispatch) => {
    try {
        const result = await axios.post('/api/auth/allow', { userId, is_approved });
        const users = result.data.data;
        toast.success('Successfully Updated');
        dispatch(setUsers(users));
    } catch (error) {
        console.log(error);
    }
}