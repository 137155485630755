import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import toast from "react-hot-toast";
import { HOST_API_KEY } from '../../config-global';

export const contentSlice = createSlice({
    name: "contents",
    initialState: {
        contents: [],
        loading: false,
        message: ""
    },
    reducers: {
        setContents(state, action) {
            state.contents = action.payload;
        },
        setMessage(state, action) {
            state.message = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        }
    }
})

export const { setContents, setMessage, setLoading } = contentSlice.actions;
export default contentSlice.reducer;

export const getContents = () => async (dispatch) => {
    try {
        const result = await axios.get("/api/content");
        dispatch(setContents(result.data.data));
    } catch (error) {
        console.log(error);
    }
}

export const insertContent = (blog) => async (dispatch) => {
    try {
        const requestOption = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: blog
        }

        const result = await fetch(`${HOST_API_KEY}/api/content`, requestOption).then(res => res.json());
        // const result = await axios.post('blog', blog);
        console.log(result, ">>>> 50")
        toast.success(result.message)
        if (result.success) {
            dispatch(setContents(result.data));
        }
        dispatch(setLoading(false));
    } catch (error) {
        console.log(error);
    }
}
